import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    // console.log("post.frontmatter.banner", post.frontmatter.banner)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.metaDescription}
        />
        <div id="hero" className="blog-page">
          <div className="container">
            <div className="row">
              <div className="col-md-7 m-auto text-center">
                <div>
                  <small style={{ color: "#00bb64" }}>
                    {post.frontmatter.publishedDate}
                  </small>
                  <h1 className="hero-title">{post.frontmatter.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="blog-post">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 py-5 mx-auto blog-inner-img">
                <div
                  className="blog-img-box mb-4"
                  style={{
                    height: "18rem",
                    backgroundImage: `url(/${
                      post.frontmatter.banner !== null
                        ? post.frontmatter.banner
                        : "https://source.unsplash.com/user/joshhild/500x500"
                    })`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <hr />

                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link
                        className="blue-btn text-blue font-700"
                        to={previous.fields.slug}
                        rel="prev"
                      >
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link
                        className="blue-btn text-blue font-700"
                        to={next.fields.slug}
                        rel="next"
                      >
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
                <div className="col pt-4 pl-0">
                  <Link className="blue-btn text-blue font-700" to="/blog">
                    Take Me Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: { regex: "/(posts)/" }
    ) {
      id
      html
      frontmatter {
        title
        publishedDate(formatString: "MMMM DD, YYYY")
        banner
        excerpt
        metaDescription
      }
    }
  }
`
